import { Router } from "@reach/router"
import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import Posts from "components/discoverPage/Posts"

const ManageMyEarlyMenopausePage = () => {
  return (
    <Layout>
      <SEO title="Women Voices" />
      <Router>
        <Posts title="Women Voices" path="/womenvoices" />
      </Router>
    </Layout>
  )
}
export default ManageMyEarlyMenopausePage
